body::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #465ff1;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 7px !important;
}

.custom-scroll-bar::-webkit-scrollbar-track {
  background: white !important;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  background: #ebe7e7 !important;
  border-radius: 10px !important;
}

.custom-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #cbcfd2; /* Change to a darker shade on hover */
}
